import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
//import App from './App';
import LoginForm from './Pages/LoginForm'
import MyBooks from './Pages/MyBooks'
import Profile from './Pages/Profile'
import CreationForm from './Pages/Creation'
import ModificationForm from './Pages/Modification'
import reportWebVitals from './reportWebVitals'
import { CookiesProvider, useCookies } from 'react-cookie'
import { ApplicationProvider } from './Providers/ApplicationProvider'

import {
  BrowserRouter as BrowserRouter,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom'

import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

const ProtectedRoute = ({ component: Component }) => {
  const navigate = useNavigate()
  const [cookies] = useCookies(['token'])

  useEffect(() => {
    if (!cookies.token) {
      navigate('/login')
    }
  }, [cookies])

  return cookies.token ? <Component /> : null
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <CssBaseline />
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={<ProtectedRoute component={MyBooks} />}
              />
              <Route
                path="/profile"
                element={<ProtectedRoute component={Profile} />}
              />
              <Route
                path="/add-book"
                element={<ProtectedRoute component={CreationForm} />}
              />
              <Route
                path="/edit-book"
                element={<ProtectedRoute component={ModificationForm} />}
              />
              <Route path="/login" element={<LoginForm />} />
            </Routes>
          </BrowserRouter>
        </CookiesProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
