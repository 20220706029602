import React, { useState } from 'react'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import Base from '../Components/Base'
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  Alert,
  TextField,
  Chip,
  Avatar,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

function Profile() {
  const [cookies] = useCookies(['token'])
  const [profile, setProfile] = useState(null)
  const [isEditable, setIsEditable] = useState(false) // État pour gérer l'édition
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const apiUrl = process.env.REACT_APP_API_URL

  // Suppression des valeurs vide par null
  const sanitizePayload = (payload) => {
    const sanitizedPayload = {}
    for (const key in payload) {
      sanitizedPayload[key] = payload[key] === '' ? null : payload[key]
    }
    return sanitizedPayload
  }

  // Fonction pour récupérer le profil
  const fetchProfile = () => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', `${apiUrl}/users/get/my-profile`, true)

    xhr.setRequestHeader('Authorization', 'Bearer ' + cookies.token)

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        try {
          const data = JSON.parse(xhr.responseText)
          setProfile(data)
        } catch (e) {
          setError('Erreur lors du traitement des données.')
        }
      } else {
        setError(
          `Erreur ${xhr.status}: Impossible de récupérer les informations de profil.`
        )
      }
      setLoading(false)
    }

    xhr.onerror = () => {
      setError('Une erreur réseau est survenue.')
      setLoading(false)
    }

    xhr.send()
  }

  // Fonction pour récupérer le profil
  const updateProfile = () => {
    const xhr = new XMLHttpRequest()
    xhr.open('PATCH', `${apiUrl}/users/update/my-profile`, true)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.setRequestHeader('Authorization', 'Bearer ' + cookies.token)

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        try {
          const data = JSON.parse(xhr.responseText)
          setProfile(data)
        } catch (e) {
          setError('Erreur lors du traitement des données.')
        }
      } else {
        setError(
          `Erreur ${xhr.status}: Profil non mis à jour, une erreur est survenue.`
        )
      }
      setLoading(false)
    }

    xhr.onerror = () => {
      setError('Une erreur réseau est survenue.')
      setLoading(false)
    }
    // const sanitizedPayload = sanitizePayload(payload)
    xhr.send(JSON.stringify(sanitizePayload(profile)))
  }

  // Charger le profil au montage
  useEffect(() => {
    fetchProfile()
  }, [])

  // Fonction pour gérer les modifications des champs
  const handleFieldChange = (field, value) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      [field]: value,
    }))
  }

  // Fonction pour basculer le mode édition
  const toggleEditMode = () => {
    if (isEditable) {
      setLoading(true)
      updateProfile()
    }
    setIsEditable((prev) => !prev)
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Alert severity="error">{error}</Alert>
      </Box>
    )
  }

  return (
    <div>
      <Base>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4">Mon profil</Typography>
          <Chip
            onClick={toggleEditMode}
            label={isEditable ? 'Sauvegarder' : 'Modifier'}
            sx={{
              fontSize: '1rem', // Agrandir le texte
              padding: '5px', // Ajouter de l'espace interne
              height: '40px', // Ajuster la hauteur
            }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          minHeight="100vh"
          padding={2}
          marginTop={2}
        >
          <Box display="flex" justifyContent="center" marginBottom={2}>
            <Avatar
              sx={{
                bgcolor: '#1976d2',
                width: 80,
                height: 80,
                fontSize: '2rem',
              }}
            >
              {profile.nickname ? profile.nickname[0] : 'U'}
            </Avatar>
          </Box>
          <Grid container spacing={2}>
            {profile && (
              <Grid container spacing={2} margin={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Nom d'utilisateur"
                    value={profile.nickname}
                    onChange={(e) =>
                      handleFieldChange('nickname', e.target.value)
                    }
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Adresse e-mail"
                    value={profile.email}
                    onChange={(e) => handleFieldChange('email', e.target.value)}
                    variant="outlined"
                    fullWidth
                    type="email"
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText="email"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Prénom"
                    value={profile.prenom}
                    onChange={(e) =>
                      handleFieldChange('prenom', e.target.value)
                    }
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Âge"
                    value={profile.age}
                    onChange={(e) => handleFieldChange('age', e.target.value)}
                    variant="outlined"
                    fullWidth
                    type="number"
                    InputProps={{
                      readOnly: !isEditable,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Base>
    </div>
  )
}

export default Profile
