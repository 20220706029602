import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useCookies } from 'react-cookie'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'

export default function SuppressionValidator({
  book_id,
  cookies,
  setRefresh,
  setOpenParent,
}) {
  const [open, setOpen] = React.useState(false)
  const [error, setError] = React.useState('')
  const apiUrl = process.env.REACT_APP_API_URL

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const deleteBook = () => {
    const xhr = new XMLHttpRequest()
    xhr.open('DELETE', `${apiUrl}/books/delete/${book_id}`)
    xhr.setRequestHeader('Authorization', 'Bearer ' + cookies.token)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.onload = function () {
      if (xhr.status === 200) {
        setOpenParent(false)
        setRefresh(true)
        handleClose()
      } else {
        setError(true)
      }
    }
    xhr.send()
  }

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen}>
        <DeleteForeverTwoToneIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Valider la suppression ?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attention, cette action est irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={deleteBook} autoFocus>
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
